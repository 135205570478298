import { Link } from "gatsby";
import * as React from "react"

import Layout from '../components/Layout';

const TermsPage: React.FC = () => {
  return (
    <Layout title="Terms of Service">
        <p><strong>SHOPMOJO TERMS OF SERVICE</strong></p>
        <p>
          <strong>ShopMojo.app</strong>(“<strong>ShopMojo</strong>”), provides white-label cross-device engagement
          platforms for retail brands aimed at optimizing a seamless shopping
          experience for consumers across devices (the “<strong>ShopMojo Platform</strong
          >”). The following terms and conditions govern all use of the ShopMojo services,
          which comprise the ShopMojo Platform, the ShopMojo website (the “<strong>ShopMojo Site</strong>”), and any
          software applications developed and offered by ShopMojo e.g., ShopMojo’s Wishlist, and any implementation,
          integration, consulting or similar services 
          (“<strong>Professional Services</strong>”) (collectively referred to as the “<strong>Services</strong>”).
          By
          subscribing or using the Services, you (“<strong>you</strong>” or
          “<strong>Client</strong>”) agree to be bound by this Terms of Service and
          ShopMojo’s&nbsp;<Link to="/privacy">Privacy Policy</Link>
          &nbsp;(together, the “<strong>Agreement</strong>“). If you do not agree to
          this Agreement, then you may not access the ShopMojo website or use the
          Services.
        </p>
        <p>
          The following terms used in this Agreement have the meanings set forth
          below:
        </p>
        <ul>
          <li>
            “<strong>Apps</strong>” means the software applications developed and
            offered by ShopMojo, e.g., Wishlist.
          </li>
          <li>
            “<strong>Client Customer</strong>” means an individual or entity who
            interacts with Client’s online retail presence which is supported in part
            by the ShopMojo Platform.
          </li>
          <li>
            “<strong>Client’s Marks</strong>” means all trademarks, service marks,
            trade names, brands, logos, designs and similar identifiers used by Client
            to identify its goods and/or services.
          </li>
          <li>
            “<strong>Client Material</strong>” means Client’s Marks and content,
            designs, specifications, instructions, software, services, data,
            information, Customer Data or materials provided by Client to ShopMojo or
            which Client integrates with or uses in connection with the Platform
            Services or any ShopMojo Material.
          </li>
          <li>
            “<strong>Customer Data</strong>” means all electronic data or information
            pertaining to Client Customers that result from Client’s use of the
            Platform Services, including Client Customers’ Non-Public Personal
            Information and Non-Personally Identifiable Information.
          </li>
          <li>
            “<strong>Non-Public Personal Information</strong>” means personally
            identifiable information, including without limitation, an individual’s
            name, email address, telephone number, financial account numbers, and
            unique identifiers associating a device with a Client Customer.
          </li>
          <li>
            “<strong>Non-Personally Identifiable Information</strong>” means
            information which, on a standalone basis, cannot identify the Client or
            Client Customer. Non-Personal Information includes usage information such
            as Client’s or Client Customer web page visits, IP addresses, log files,
            unique device identifiers, operating system type, browser type, GPS data
            and any links clicked on to interact with our Services, and other usage
            information collected from cookies and other tracking technologies.
          </li>
          <li>
            “<strong>Platform Services</strong>” means ShopMojo’s proprietary software,
            code, algorithms, and other tools comprising the ShopMojo Platform and ordered
            by Client pursuant to a Subscription Order.
          </li>
          <li>
            “<strong>Professional Services</strong>” means the implementation,
            integration, consulting and similar services described in a Subscription
            Order.
          </li>
          <li>
            “<strong>Services</strong>” means the Apps, Platform Services, and
            Professional Services, and services offered by ShopMojo.
          </li>
          <li>
            “<strong>Subscription Order</strong>” means the physical, electronic or
            online ShopMojo order form, as applicable, which is accepted by ShopMojo and
            further describes the Services purchased by Client. Each Subscription
            Order entered into hereunder shall be governed by the terms hereof. In the
            event of a conflict or inconsistency between a term in a Subscription
            Order and this Agreement, the terms set forth in the Subscription Order
            shall control.
          </li>
          <li>
            “<strong>Subscription Term</strong>” means the subscription period for
            Platform Services and/or Professional Services set forth in an applicable
            Subscription Order.
          </li>
          <li>
            “<strong>ShopMojo Marks</strong>” means all trademarks, service marks, trade
            names, brands, logos, designs and similar identifiers used by ShopMojo to
            identify its goods and/or services.
          </li>
          <li>
            “<strong>ShopMojo Material</strong>” means all ShopMojo Marks and all content,
            designs, specifications, instructions, software, code, services, data,
            information or materials provided by ShopMojo to Client.
          </li>
        </ul>
        <ul>
          <li>
            Provision of the Services. ShopMojo shall make the Services available to
            Client pursuant to this Agreement and any applicable Subscription Order
            during the Subscription Term. If Client subscribes to use a specific App,
            such App will be available for Client’s use during the Subscription Term.
          </li>
          <li>
            Grant of Limited License. ShopMojo hereby grants to Client a limited,
            non-exclusive, and non-transferable license to use the Platform Services
            and Apps under Client’s Marks during the Subscription Term solely for
            Client’s internal use and not, by implication or otherwise, to any parent,
            subsidiary or affiliate of Client. An individual or entity visiting the
            ShopMojo website who does not agree to a Subscription Order hereby receives a
            revocable, limited right to access the ShopMojo website solely as necessary to
            review ShopMojo’s publicly available materials about its products and services
            (terms herein which by their nature apply to Clients who agree to a
            Subscription Order (e.g., right to use the Apps) shall not apply to
            individuals or entities who do not agree to a Subscription Order). The
            licenses and rights&nbsp;granted herein do not transfer any ownership in
            the Platform Services, the Apps, the ShopMojo website or any part thereof.
            Except as expressly provided herein, this Agreement grants no express or
            implied license, right or other interest in or to any of ShopMojo’s
            intellectual property rights and ShopMojo reserves all rights, title and
            interest in and to the Services, including all intellectual property
            rights.
          </li>
          <li>
            Trademarks. Client shall have exclusive ownership of, and all rights in
            and to, Client’s Marks, and any other Client Material provided to ShopMojo in
            connection with Client’s limited license to use the Platform Services and
            Apps under Client’s Marks. Notwithstanding the foregoing, Client hereby
            grants ShopMojo a worldwide, limited, perpetual, non-exclusive,
            non-transferable license to use Client’s Marks solely in connection with
            the provision of the Platform Services and the Apps. ShopMojo shall have
            exclusive ownership of, and all rights in and to, all ShopMojo Marks. Nothing
            herein shall be construed as granting to Client any rights in or to, or
            the right to use, the ShopMojo Marks. Any use of the ShopMojo Marks by Client
            requires the prior written consent of ShopMojo.
          </li>
          <li>
            Restrictions. Except as expressly stated herein, Client may not: (a)
            modify, adapt, translate, copy or create derivative works based on the
            Services; (b) reverse engineer the Services or any part thereof; or (c)
            access the Services for the purpose of (i) developing a competitive
            product or service, or (ii) copying any ideas, features, functions or
            graphics of the Services.
          </li>
          <li>
            Customer Data. Subject to the exceptions in the Privacy Policy, we will
            use Customer Data to provide the Services as permitted by this Agreement.
            Through provision of the Services, ShopMojo may collect Customer Data. As
            between Client and ShopMojo, Client exclusively owns all rights, title and
            interest in and to all Customer Data. Client, however, grants ShopMojo a
            royalty-free, perpetual, irrevocable, non-exclusive license to access and
            analyze Customer Data in order to provide or improve the provision of the
            Services, validate and compute fees for any Services, and to generate
            analytics on customer behavior. ShopMojo may use Customer Data internally to
            associate a Client Customer with their devices and share the associations
            and related data among the ShopMojo Services to enhance interoperability.
            Moreover, ShopMojo may aggregate Non-Personally Identifiable Information to
            use for any purpose, including selling the aggregated information to third
            parties. We will not share Non-Public Personal Information (information
            that identifies individual customers), except as allowed by the Privacy
            Policy and to provide improved identity capabilities for ShopMojo Clients that
            consume the ShopMojo Services.
          </li>
          <li>
            Improvements. ShopMojo shall own all rights, title and interest, including but
            not limited to, all intellectual property rights, in and to all
            improvements to the Services and any new programs, upgrades, modifications
            or enhancements developed by ShopMojo in connection with providing the
            Services to Client, even where such refinements and improvements result
            from a request by or feedback from Client. To the extent, if any, that
            ownership in such refinements and improvements does not automatically vest
            in ShopMojo by virtue of this Agreement or otherwise, Client hereby assigns to
            ShopMojo all rights, title, and interest which Client may have in and to such
            refinements and improvements.
          </li>
          <li>
            Modifications to Platform Services or Apps. Client acknowledges that ShopMojo
            may add, delete, change, suspend or terminate features, functionality or
            other aspects of the Services or the Apps at any time and for any reason,
            and that ShopMojo is under no obligation to upgrade or update the Platform
            Services or the Apps or ensure compatibility or continuity between any
            version of the Platform Services or the Apps and any prior or subsequent
            versions thereof; provided however that if ShopMojo makes a material change to
            the Platform Services or Apps, a Client who executed a Subscription Order
            may terminate the Subscription Order with written notice within seven (7)
            days of the date of such material change.
          </li>
        </ul>
        <ol>
          <li>
            <strong>TERMS OF USE.</strong>
            <ul>
              <li>
                Client’s Responsibilities. Client is solely responsible for: (a) the
                accuracy, quality, integrity, legality, reliability, and
                appropriateness of all Client Material provided by Client to ShopMojo or
                which Client integrates with or uses in connection with the Services;
                and (b) the creation, initiation and sending of emails utilizing the
                Services, including but not limited to, content (other than material
                provided by ShopMojo to Client), recipients, and timing of such emails.
                Client shall prevent unauthorized access to, or use of, the Services,
                and notify ShopMojo promptly of any unauthorized access or use. Client
                shall comply with all applicable local, state, federal and foreign
                laws in using the Services.
              </li>
              <li>
                Privacy. Client agrees:
                <ul>
                  <li>
                    to publish, maintain and abide by a privacy policy that: (i) is
                    consistent with Client’s use of the information Client collects
                    from individuals using the Services; (ii) allows ShopMojo to use
                    Customer Data as outlined in this Agreement; (iii) complies with
                    applicable law and applicable self-regulatory principles, if any;
                    and (iv) prominently and adequately discloses Client’s collection,
                    use, disclosure, storage, and aggregation of any information
                    Client collects from individuals using the Services, including
                    with respect to Non-Public Personal Information, collected via
                    cookies, locally-stored objects or other tracking technologies;
                  </li>
                  <li>
                    that Client will not (and will not allow any third party to) use
                    the Platform Services or the Apps to track or collect Non-Public
                    Personal Information without properly informing (e.g., through a
                    privacy policy) individuals of Client’s data collection, use,
                    disclosure, storage, and aggregation practices (and only so long
                    as such use conforms with any of Client’s other obligations under
                    this Agreement);
                  </li>
                  <li>
                    that Client will not do any act to render any of the above
                    obligations and notices untrue and will not include contradictory
                    language in any privacy policy, that Client is responsible for
                    ensuring that no other statement in Client’s privacy policy
                    contradicts or nullifies any of the above statements, that Client
                    will not rely on ShopMojo’s opinion or direction as a substitute for
                    legal advice, and that Client will retain sole responsibility for
                    ensuring that its conduct and policies (including Client’s privacy
                    policy) are sufficiently protective of Client’s end users’ rights.
                  </li>
                </ul>
              </li>
              <li>
                User Guidelines. Client shall use the Services solely for its internal
                business purposes as contemplated by this Agreement and shall not: (a)
                license, sublicense, sell, resell, rent, lease, transfer, assign,
                distribute or otherwise commercially exploit or make the Platform
                Services available to any third party except as contemplated by this
                Agreement; (b) send via or in connection with the use of the Services
                infringing, obscene, threatening, defamatory, fraudulent, abusive, or
                otherwise unlawful or tortious material, including material that is
                harmful to children or violates third-party privacy rights; (c) send
                via or in connection with the use of the Services any unsolicited
                commercial or non-commercial communication; (d) send via, upload to,
                or store within the Services any viruses, worms, time bombs, Trojan
                horses and other harmful or malicious code, files, scripts, agents or
                programs; (e) interfere with or disrupt the integrity or performance
                of the Services or the information contained therein; or (f) attempt
                to gain unauthorized access to the Services or its related systems or
                networks.
              </li>
            </ul>
          </li>
          <li>
            <strong>FEES AND PAYMENT.</strong>
            <ul>
              <li>
                Services Fees. All Services are available according to pricing as set forth
                in an applicable Subscription Order. Pricing is subject to change from
                time to time on a prospective basis at ShopMojo’s sole discretion;
                provided however that you may terminate a Subscription Order within
                fourteen (14) days of receiving a price adjustment notice, and your
                failure to terminate this Agreement on or before the expiration of
                such termination period shall constitute your acceptance of the new
                pricing terms. Client agrees to pay all fees due with respect to
                Client’s use of the Services. Fees are payable in U.S. dollars. Except
                as otherwise specified in an applicable Subscription Order, fees are
                based on the Services purchased and not actual usage. Platform
                Services purchased cannot be decreased during the Subscription Term.
              </li>
              <li>
                Payment. Payment of all fees must be made in advance by a valid credit
                card accepted by ShopMojo unless other payment arrangements have been made
                between you and ShopMojo. By approving a Subscription Order for the
                Platform Services, you authorize ShopMojo to charge your credit card
                monthly, in advance, for the fees set forth in the Subscription Order.
                If ShopMojo is for any reason unable to effect automatic payment via your
                credit card, you will be notified by email and access to the Platform
                Services will be disabled until payment is received. Late payments
                shall accrue interest of 1.5% per month, or the maximum rate allowable
                by law, whichever is less.
              </li>
              <li>
                Taxes. Unless otherwise stated, fees do not include any direct or
                indirect local, state, or federal taxes, levies, duties or similar
                governmental assessments of any nature, including value-added, use or
                withholding taxes (collectively, “Taxes”). Client is responsible for
                paying all Taxes associated with its purchases hereunder, excluding
                taxes based on ShopMojo’s net income or property. If ShopMojo has the legal
                obligation to collect or pay Taxes for which Client is responsible
                under this Agreement, the appropriate amount shall be paid by Client.
              </li>
            </ul>
          </li>
          <li>
            <strong>EU PERSONAL DATA TRANSFERS.</strong>
            <ul>
              <li>
                ShopMojo processes Customer Data in the United States, and accordingly, Client
                represents and warrants that the transfer of Customer Data as contemplated
                by this Agreement complies with all applicable laws, including but not
                limited to the General Data Protection Regulation (“GDPR”) (EU) 2016/679.
                To the extent Client submits Customer Data which constitutes “personal
                data” of individuals subject to the privacy laws of the European Economic
                Area or Switzerland (collectively “EU Personal Data”), Client acknowledges
                that in all cases ShopMojo acts as the processor of EU Personal Data and
                Client remains the controller of EU Personal Data.
              </li>
              <li>
                This Agreement incorporates by reference the required contractual
                provisions from GDPR Article 28 between controllers (i.e. Client) and
                processors (i.e. ShopMojo).
              </li>
              <li>
                This Agreement incorporates by reference the EU Standard Contractual
                Clauses set forth in the Decision 2010/87/EU of February 5, 2010.
              </li>
            </ul>
          </li>
        </ol>
        
        <ol>
          <li>
            <strong>REPRESENTATIONS, WARRANTIES AND DISCLAIMERS.</strong>
            <ul>
              <li>
                Mutual Representations and Warranties. Each party represents and
                warrants that:
                <ul>
                  <li>
                    it has the right and authority to enter into this Agreement;
                  </li>
                  <li>
                    by entering into this Agreement it will not violate, conflict with
                    or cause a material default under any other agreements;
                  </li>
                  <li>
                    at all times during the term of this Agreement, it shall comply
                    with all applicable laws, rules and regulations, including without
                    limitation, Section 5 of the Federal Trade Commission Act and
                    other statutes and regulations prohibiting unfair or deceptive
                    marketing practices, the CAN-SPAM Act, and other consumer
                    protection and privacy laws, regulations, and guidelines; and
                  </li>
                  <li>
                    to its knowledge, no part of any materials provided by it to the
                    other party infringes upon any third-party copyright, patent,
                    trademark, trade secret or other proprietary or intellectual
                    property rights.
                  </li>
                </ul>
              </li>
              <li>
                Client Representations and Warranties. Client represents and warrants
                that:
                <ul>
                  <li>
                    Client has exclusive ownership of, and all rights in and to, all
                    Client’s Marks, and all other Client Material provided to ShopMojo or
                    which Client integrates with or uses in connection with the
                    Services or any ShopMojo Material;
                  </li>
                  <li>
                    Client has the exclusive ownership of, and all rights in and to,
                    or the right to license and provide the Client Material to ShopMojo,
                    and that the use, copying, modification, aggregation, integration
                    and publication of the Client Material by ShopMojo in connection with
                    its provision of the Services: (i) will not infringe, violate or
                    misappropriate any third-party copyright, patent, trade secret, or
                    other proprietary rights; (ii) will not infringe any rights of
                    publicity or privacy; (iii) will not be defamatory or obscene or
                    otherwise violate any law;
                  </li>
                  <li>
                    Client will enter into binding and enforceable agreements with
                    each end user of the Platform Services and Apps provided under
                    this Agreement and any applicable Subscription Order, that govern
                    their use of the Platform Services and Apps, including terms of
                    service and privacy policies that provide prominent and clear
                    notices to and requires consents from end users with respect to
                    the collection, use, storage, aggregation or other processing of
                    Client’s Customer Data, including Non-Public Personal Information,
                    and including any transfer of such Customer Data and Non-Public
                    Personal Information to ShopMojo in connection with the provision of
                    the Services; and
                  </li>
                  <li>
                    Client will, at its own expense, comply with all applicable laws,
                    rules and regulations, including without limitation all privacy
                    and data protection laws, in connection with Client’s use of the
                    Services and information derived therefrom (including but not
                    limited to Customer Data) and the performance of Client’s
                    obligations under this Agreement.
                  </li>
                </ul>
              </li>
              <li>
                Disclaimer of Warranties. EXCEPT AS SPECIFICALLY SET FORTH IN THIS
                SECTION 6, THE SERVICES AND ShopMojo MATERIAL PROVIDED BY ShopMojo IN
                CONNECTION WITH THIS AGREEMENT ARE PROVIDED SOLELY ON AN AS-IS BASIS,
                WITHOUT REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
                INCLUDING WITHOUT LIMITATION, ANY WARRANTY ARISING OUT OF ANY COURSE
                OF DEALING, CUSTOM OR USAGE OF TRADE, WARRANTY OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, NON-INTERFERENCE AND
                NON-INFRINGEMENT. ShopMojo DOES NOT WARRANT THAT THE SERVICES WILL MEET
                CLIENT’S REQUIREMENTS OR ACHIEVE THE RESULTS DESIRED BY CLIENT OR THAT
                ANY CODE, FIXES, PATCHES, REVISIONS, UPDATES OR MODULES, IF ANY
                PROVIDED BY ShopMojo IN CONNECTION WITH THIS AGREEMENT OR THE SERVICES,
                WILL BE BUG-FREE OR ERROR-FREE OR THAT ANY CORRECTIONS OR FIXES WILL
                BE EFFECTIVE.
              </li>
            </ul>
          </li>
        </ol>
        <ul>
          <li>
            Indemnification by Client. Client agrees to defend, indemnify and hold
            ShopMojo and its officers, directors, employees, and agents (each a “ShopMojo
            Indemnitee”) harmless from and against any claims, demands, lawsuits,
            actions, proceedings or investigations made by any third party (“Claim”)
            from and against any fees, costs, sanctions, penalties, damages, losses or
            expenses (including reasonable attorneys’ fees) due to or arising out of:
            (a) Client’s use or misuse of the Services; (b) allegations that Client
            Material and/or any other information or material provided to ShopMojo by
            Client in connection with the provision of the Services, infringe the
            intellectual property rights or other rights of a third party; (c) ShopMojo’s
            use, in connection with its provision of the Services as provided herein,
            of any Customer Data provided by Client which has harmed a third party;
            (d) Client’s violations of applicable laws, rules or regulations in
            connection with its use of the Services, including without limitation,
            privacy laws; and (e) Client’s breach of the representations, warranties
            and covenants made by Client herein.
          </li>
          <li>
            Procedure. ShopMojo agrees to use reasonable efforts to promptly notify Client
            in writing of a Claim for which indemnification is sought and to provide
            Client with all non-monetary assistance, information and authority
            reasonably necessary for the defense and settlement of such Claim. ShopMojo
            reserves the right, at Client’s expense, to assume the exclusive defense
            and control of any Claim for which Client is required to indemnify any
            ShopMojo Indemnitee and Client agrees to cooperate with the ShopMojo Indemnitee in
            the defense of the Claim. Client shall obtain ShopMojo’s prior written consent
            to any settlement of a Claim, said consent not to be unreasonably
            withheld, conditioned or delayed.
          </li>
        </ul>
        <ol>
          <li><strong>LIMITATION OF LIABILITY AND EXCLUSION OF DAMAGES.</strong></li>
        </ol>
        <ol>
          <li>
            EXCEPT FOR A PARTY’S INDEMNIFICATION OBLIGATIONS, IN NO EVENT SHALL A
            PARTY’S (INCLUDING THE PARTY’S OFFICERS, DIRECTORS, EMPLOYEES AND
            AFFILIATES) AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS
            AGREEMENT, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF
            LIABILITY, EXCEED, IN THE AGGREGATE, THE TOTAL AMOUNTS ACTUALLY PAID BY
            CLIENT TO ShopMojo FOR THE SERVICES LESS ANY REFUNDS OR CREDITS RECEIVED BY
            CLIENT; HOWEVER, SUCH LIMITATION SHALL NOT RELIEVE CLIENT FROM HIS PAYMENT
            OBLIGATIONS FOR ANY OUTSTANDING AND DUE INVOICES FOR SERVICES RENDERED. IN
            NO EVENT SHALL A PARTY (INCLUDING THE PARTY’S OFFICERS, DIRECTORS,
            EMPLOYEES AND AFFILIATES) HAVE ANY LIABILITY TO THE OTHER PARTY FOR ANY
            LOST PROFITS OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
            CONSEQUENTIAL OR SPECIAL DAMAGES OF ANY KIND OR NATURE HOWEVER CAUSED AND,
            WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, WHETHER
            OR NOT SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            BOTH PARTIES UNDERSTAND AND AGREE THAT THE LIMITATIONS AND EXCLUSIONS SET
            FORTH HEREIN REPRESENT THE PARTIES’ AGREEMENT AS TO THE ALLOCATION OF RISK
            BETWEEN THE PARTIES IN CONNECTION WITH THEIR OBLIGATIONS UNDER THIS
            AGREEMENT. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION
            OR EXCLUSION MAY NOT APPLY TO YOU, AND YOU MAY ALSO HAVE OTHER LEGAL
            RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION. NO CLAIM MAY BE
            BROUGHT BY CLIENT UNDER THIS AGREEMENT MORE THAN ONE YEAR AFTER THE
            ACCRUAL OF THE CLAIM.
          </li>
        </ol>
        <ol>
          <li>
            <strong>TERM AND TERMINATION.</strong>
            <ul>
              <li>
                Agreement Term. This Agreement commences upon Client’s first access to
                or use of the Services and continues until terminated as set forth in
                this Section 9 (the “Term”).
              </li>
              <li>
                Subscription Term. A subscription to the Services commences on the
                date on which ShopMojo makes the ShopMojo Platform available to Client upon
                payment of the applicable monthly subscription fee. Unless otherwise
                set forth in a Subscription Order, subscriptions shall automatically
                renew for additional periods of one month for the fees set forth in
                the applicable Subscription Order unless either party gives the other
                notice of non-renewal at least ten (10) days prior to the end of the
                relevant Subscription Term.
              </li>
              <li>
                Termination for Convenience. Either party may terminate this Agreement
                without cause upon fourteen (14) days written notice to the other
                party.
              </li>
              <li>
                Termination for Cause. A party may terminate this Agreement: (a) upon
                seven (7) days written notice of a material breach to the other party
                if such breach remains uncured at the expiration of such period; or
                (b) immediately if the other party becomes the subject of a petition
                in bankruptcy or any other proceeding relating to insolvency,
                receivership, liquidation or assignment for the benefit of creditors.
                Upon any termination for cause by ShopMojo, Client shall remain obligated
                to pay all fees owed, which fees shall become immediately due and
                payable in full.
              </li>
              <li>
                Effect of Termination. Upon termination or expiration of this
                Agreement all licenses granted herein shall be immediately revoked and
                terminated. The rights and obligations of the parties set forth in
                Sections 5, 6, 7, 8 and 10 shall survive termination or expiration of
                the Agreement, and you shall immediately remit all outstanding amounts
                due under the this Agreement which accrued prior to the termination of
                this Agreement. THE TERMINATION PROVISIONS IN THIS SECTION 9 STATE
                CLIENT’S SOLE RIGHTS AND EXCLUSIVE REMEDY FOR SHOPMOJO’S FAILURE TO
                DEVELOP, OPERATE, OR PROVIDE THE PLATFORM SERVICES.
              </li>
            </ul>
          </li>
        </ol>
        <ul>
          <li>
            Relationship of the Parties. Nothing in this Agreement shall be construed
            to create a partnership, joint venture, affiliate, employer-employee or
            principal-agent relationship, but rather the relationship of the parties
            shall be that of independent parties.
          </li>
          <li>
            No Third-Party Beneficiaries. The parties agree that there are no
            third-party beneficiaries to this Agreement.
          </li>
          <li>
            Waiver and Cumulative Remedies. The failure to enforce any provisions of
            this Agreement shall in no way be construed as a waiver of such provisions
            and shall not affect a party’s enforcement rights thereafter. Other than
            as expressly stated herein, the remedies provided herein are in addition
            to, and not exclusive of, any other remedies of a party at law or in
            equity.
          </li>
          <li>
            Force Majeure. Neither party shall be deemed in default of this Agreement
            to the extent that performance of its obligations or attempts to cure any
            breach are delayed or prevented by reason of any act of God, fire, natural
            disaster, accident, riots, acts of government, shortage of materials or
            supplies, or any other cause beyond the reasonable control of such party.
          </li>
          <li>
            Attorneys’ Fees. If either party hereto resorts to legal action for the
            redress of a breach of this Agreement, the prevailing party shall be
            entitled to an award of costs and reasonable attorneys’ fees.
          </li>
          <li>
            Changes to Agreement. ShopMojo may modify the terms of this Agreement and may
            discontinue or change any aspects of the Services at its sole discretion
            (on a prospective basis); provided, however, that ShopMojo provides adequate
            written or electronic notice to you of any material changes (the posting
            of the Agreement on the ShopMojo Site shall constitute effective electronic
            notice). If Client executed a Subscription Order and does not agree to
            such changes, Client may terminate the Subscription Order with written
            notice within seven (7) days of such changes. You should review the ShopMojo
            Site regularly. Changes will not apply retroactively and will become
            effective upon those changes being made live.
          </li>
          <li>
            Assignment. This Agreement shall be binding upon and inure to the benefit
            of the parties and their successors and assigns; provided, however, that
            this Agreement shall not be assigned by Client without ShopMojo’s prior
            written consent. ShopMojo may assign this Agreement in connection with a
            change of control, reorganization, or sale of at least a majority of its
            assets or outstanding voting securities.
          </li>
          <li>
            Severability. If any provision of this Agreement shall be held invalid or
            unenforceable, the remaining provisions shall not be affected thereby.
          </li>
          <li>
            Governing Law. This Agreement shall be governed by the laws of the State
            of Washington, without regard to its choice-of-law principles. Any dispute
            arising out of or related to this Agreement or the Services shall be
            subject to the exclusive jurisdiction and venue of the Washington state
            courts and/or the United States District Court for the Western District of
            Washington, and the parties hereby agree to the personal and exclusive
            jurisdiction and venue of these courts.
          </li>
          {/* <li>
            Notices. All notices under this Agreement shall be in writing and shall be
            deemed to have been given upon: (a) personal delivery; (b) the second
            business day after mailing; (c) the second business day after sending by
            confirmed facsimile; or (d) the second business day after sending by
            email. Except as otherwise agreed, all notices required or permitted to be
            provided to Client hereunder shall be sent to the mail address or email
            address provided by Client to ShopMojo in connection with the Subscription
            Order or through the ShopMojo Site. Except as otherwise agreed, all notices
            required or permitted to be provided to ShopMojo shall be sent to support@shopmojo.app
          </li> */}
          <li>
            Entire Agreement. This Agreement constitutes the entire agreement between
            the parties and supersedes all prior and contemporaneous agreements,
            whether written or oral, concerning its subject matter. No modification,
            amendment, or waiver of any provision of this Agreement shall be effective
            unless in writing and signed by the party against whom the modification,
            amendment or waiver is to be asserted.
          </li>
        </ul>      
    </Layout>
  )
}

export default TermsPage
